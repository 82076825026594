// 社团巡礼
<template>
  <div class="campusLife">
    <TabPageLayout activeCode="xiaoyuanshenghuo">
      <div class="container clubTour">
        <div class="div_mod div_mod2 div_mod1">
          <div class="div_tit" v-for="(block, index) in blocks" :key="block.id">
            <h2>{{ block.title }}</h2>
            <!-- 社团介绍 -->
            <div class="div_text" v-html="block.bz">
            </div>

            <div v-if="index === 1">
              <div class="lunbo">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      v-for="item in blockArticles[index]"
                      :key="item.id"
                    >
                      <a>
                        <img :src="item.files" alt="" />
                        <p>{{ item.title }}</p>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="direction">
                  <span class="s_prev"><i></i></span>
                  <span class="s_next"><i></i></span>
                </div>
              </div>
            </div>
            <template v-if="index === 2">
              <ul class="ul_ct" v-if="clubPics.length > 0">
                <li
                  v-for="clubPic in clubPics"
                  :key="clubPic.id"
                  :style="{
                    backgroundImage: `url(${clubPic?.bgimg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    height: '303px',
                  }"
                  @click="handleDialogShow(clubPic)"
                >
                  <div class="divbox">
                    <span class="s1">{{ clubPic.title }}</span>
                    <span class="s2">查看更多</span>
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </TabPageLayout>
    <CommonDialog
      :visible.sync="oDialog.visible"
      :titleText.sync="oDialog.title"
      :showCancel="false"
      :showOK="false"
      :size="oDialog.size"
    >
      <SwiperContainer v-if="oDialog.visible" :typeId="oDialog.typeId" />
    </CommonDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ArticleCard2 from './components/ArticleCard2.vue'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import CommonDialog from '../../../lvya-common/src/coms-web/dialog/CommonDialog.vue'
import SwiperContainer from './components/SwiperContainer.vue'
export default {
  components: {
    TabPageLayout,
    ArticleCard2,
    CommonDialog,
    SwiperContainer,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return (
        this.nav?.find((item) => item.code === 'xiaoyuanshenghuo')?.stypes[1]
          ?.id || undefined
      )
    },
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
      clubPics: [],
      oDialog: {
        visible: false,
        title: ' ',
        typeId: null,
      },
    }
  },
  methods: {
    async handleDialogShow(item) {
      this.oDialog.title = item.title
      this.oDialog.typeId = item.id
      this.oDialog.visible = true
    },
    async getClubPics() {
      const categories = await useGetCategories(this.blocks[2].id)
      console.log(categories, 'categories club pics')
      this.clubPics = categories.list
    },
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const articles = []
      for (const block of this.blocks) {
        console.log(block, 'block')
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        console.log(resData, 'articles')
        articles.push(resData?.list)
        console.log(articles, 'articles')
      }
      this.blockArticles = articles
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getClubPics()
    await this.getArticles()
    new Swiper('.clubTour .lunbo .swiper-container', {
      loop: true,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 30,
      navigation: {
        nextEl: '.lunbo .s_next',
        prevEl: '.lunbo .s_prev',
      },

      breakpoints: {
        1024: {
          // 当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    })
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/campusLife.css";

.lunbo .direction span {
  top: 50%;
}

/deep/.el-dialog {
  .el-dialog__body {
    // padding: 0;
    height: auto;
    max-height: 70vh;
  }
}
@media screen and (max-width: 768px) {
  /deep/.el-dialog {
    width: 80%;
  }
}
</style>

